import React, { useState } from "react";
import styled from "styled-components";
import { StaticPageContainer } from "../../../components/docs/shared-components/PageContainers";
import DocsHelmet from "../../../components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import DocumentationSection from "../../../components/docs/DocumentationSection";
import CodeExample from "components/docs/code-examples/CodeExample";
import Image from "react-bootstrap/Image";
import WebhookLogGif from "../../../assets/images/docs/webhooks/WebhooksGif.gif";
import { GuideContainer } from "./overview";
import {
  Left,
  Right,
  FlexRow,
  LinkTitle,
  CrossGuideLink,
  ChevronLeft,
  ChevronRight,
} from "components/docs/navigation/CrossGuideLinks";
import { RouterLocation } from "types/types";
import { NavBarSelectorButton } from "pages/sdk";
import CreateWebhook from "../../../assets/images/docs/webhooks/CreateWebhook.svg";
import DashboardWebhooks from "../../../assets/images/docs/webhooks/DashboardWebhooks.svg";
import WebhookSecurity from "../../../assets/images/docs/webhooks/WebhookSecurity.svg";

import { Select } from "@merge-api/merge-javascript-shared";
import ParameterRow from "components/docs/ParameterRow";
import HeaderBar from "components/docs/layout/HeaderBar";

export const PseudoPaddedSection = styled.section`
  :target {
    scroll-margin-top: 120px;
  }
`;

export const WarningAsideText = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: #121314;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 36px;
`;

type WebhookContentType =
  | "ACCOUNT_LINKED"
  | "LINKED_ACCOUNT_SYNCED"
  | "COMMON_MODEL_SYNCED"
  | "LINKED_ACCOUNT_ISSUES"
  | "ASYNC_PASSTHROUGH_COMPLETED"
  | "CHANGED_DATA"
  | "DELETED_DATA"
  | "LINKED_ACCOUNT_DELETED";

const WebhookContentTypeMap = {
  ACCOUNT_LINKED: "ACCOUNT_LINKED",
  LINKED_ACCOUNT_SYNCED: "LINKED_ACCOUNT_SYNCED",
  COMMON_MODEL_SYNCED: "COMMON_MODEL_SYNCED",
  LINKED_ACCOUNT_ISSUES: "LINKED_ACCOUNT_ISSUES",
  ASYNC_PASSTHROUGH_COMPLETED: "ASYNC_PASSTHROUGH_COMPLETED",
  CHANGED_DATA: "CHANGED_DATA",
  DELETED_DATA: "DELETED_DATA",
  LINKED_ACCOUNT_DELETED: "LINKED_ACCOUNT_DELETED",
};

interface WebhookDropdownOption {
  label: string;
  value: WebhookContentType;
}

const webhookDropdownOptions: WebhookDropdownOption[] = [
  {
    label: "Linked Account linked",
    value: "ACCOUNT_LINKED",
  },
  {
    label: "Linked Account synced",
    value: "LINKED_ACCOUNT_SYNCED",
  },
  {
    label: "Linked Account deleted",
    value: "LINKED_ACCOUNT_DELETED",
  },
  {
    label: "Linked Account issues",
    value: "LINKED_ACCOUNT_ISSUES",
  },
  {
    label: "Common model synced",
    value: "COMMON_MODEL_SYNCED",
  },
  {
    label: "Changed data",
    value: "CHANGED_DATA",
  },
  {
    label: "Deleted data",
    value: "DELETED_DATA",
  },
  {
    label: "Async Passthrough completed",
    value: "ASYNC_PASSTHROUGH_COMPLETED",
  },
];

const WEBHOOK_CONTENT_TYPE_TO_PAYLOAD_PATH_MAP: Record<string, string> = {
  ACCOUNT_LINKED: "account-linked",
  LINKED_ACCOUNT_SYNCED: "linked-account-synced",
  COMMON_MODEL_SYNCED: "common-model-synced",
  LINKED_ACCOUNT_ISSUES: "issues",
  ASYNC_PASSTHROUGH_COMPLETED: "async-passthrough",
  CHANGED_DATA: "changed-data",
  DELETED_DATA: "deleted-data",
  LINKED_ACCOUNT_DELETED: "linked-account-deleted",
};

const WEBHOOK_CONTENT_TYPE_TO_PAYLOAD_DESCRIPTION_MAP: Record<string, string> = {
  ACCOUNT_LINKED: "Receive an alert when a new Linked Account is linked.",
  LINKED_ACCOUNT_SYNCED: "Receive an alert when selected Linked Accounts are synced.",
  COMMON_MODEL_SYNCED:
    "Receive an alert when selected Common Models are synced. This option is best if you have a lot of data to keep in sync.",
  LINKED_ACCOUNT_ISSUES:
    "Receive an alert when a Linked Account issue is created, reopened, or resolved.",
  ASYNC_PASSTHROUGH_COMPLETED: "Receive an alert when an Async Passthrough request has resolved.",
  CHANGED_DATA:
    "Receive a payload containing the most recent data when selected Common Models are created or updated.",
  DELETED_DATA:
    "Receive a payload containing the most recent data when selected Common Models are deleted. ",
  LINKED_ACCOUNT_DELETED: "Receive an alert when selected Linked Accounts are deleted.",
};

interface DocumentationWebhooksMergeToYouPage {
  location: RouterLocation;
}

const findWebhookDropdownOption = (inputLabel: string) => {
  const foundObject = webhookDropdownOptions.find((obj) => obj.value === inputLabel);
  return foundObject || null; // Return the found object or null if not found
};

const DocumentationWebhooksMergeToYouPage = ({ location }: DocumentationWebhooksMergeToYouPage) => {
  const [selectedWebhookPayload, setSelectedWebhookPayload] =
    useState<WebhookContentType>("ACCOUNT_LINKED");

  const [selectedDropdownOption, setSelectedDropdownOption] = useState<WebhookDropdownOption>({
    label: "Linked Account linked",
    value: "ACCOUNT_LINKED",
  });

  const title = "Merge webhooks";
  const description = "Configure Merge webhooks that send data payloads to your app.";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={`${title} sent to you`} subtitle={description} />

      <DocumentationSection title="Overview">
        <p>
          Webhooks offer a real-time way to notify your app when data changes. This guide teaches
          how to setup these webhooks, which are inbound POST requests to your API, allowing you to
          create, update, or delete data in your app based on Merge events.
        </p>
        <p>Webhooks are shared across your Merge organization.</p>
      </DocumentationSection>
      <hr className=" my-9" />
      <DocumentationSection title="Create webhooks">
        <>
          <p className="mb-0">
            To create webhooks, go to the{" "}
            <a href="https://app.merge.dev/configuration/webhooks">Webhooks</a> management console
            under the Advanced configuration in your Merge dashboard and click <b>+Webhook.</b>
          </p>
          <ImageContainer>
            <CreateWebhook className="rounded-lg" />
          </ImageContainer>
          <p>
            On the next page, add the URL that you want Merge to send a POST request to. This URL
            should point to a POST route in your API that you&apos;ll build to handle the incoming
            payload.
          </p>
          <p>
            Select the event type that you want to trigger the webhook. We recommend the{" "}
            <strong className="font-semibold">Common Model sync</strong> and/or{" "}
            <strong className="font-semibold">changed data webhooks</strong> if you have a lot of
            data to keep in sync. You can detect the affected data models within your API by parsing
            the JSON payload.
          </p>
          <p className="mb-0">
            If you want to test your webhook, click{" "}
            <strong className=" font-semibold">Send test POST request</strong> next to your URL.
            This will cause Merge to send a POST request carrying a sample payload to the URL
            you&apos;ve specified, where you can log the output and program your endpoint to do
            something in response to the payload.
          </p>
          <ImageContainer>
            <DashboardWebhooks className="rounded-lg" />
          </ImageContainer>
        </>
      </DocumentationSection>
      <hr className="my-9" />
      <DocumentationSection title="Payload Properties">
        <>
          <ParameterRow
            key="hook"
            name="hook"
            type="Object"
            required={false}
            isShowingRequiredFlag={false}
            description="The webhook that was triggered."
            isSingleLine={true}
          />
          <ParameterRow
            key="linked_account"
            name="linked_account"
            type="String"
            required={false}
            isShowingRequiredFlag={false}
            isSingleLine={true}
            description="The user whose data has changed."
          />
          <ParameterRow
            key="data"
            name="data"
            type="Object"
            required={false}
            isShowingRequiredFlag={false}
            isSingleLine={true}
            description="The affected data model. The structure of each model can be found in our API documentation"
          />
          <hr className="mb-9" />
        </>
      </DocumentationSection>
      <DocumentationSection title="Webhook event types">
        <div className="flex flex-col xl:flex-row mt-6">
          <div className="xl:hidden w-72 mb-6">
            <Select
              options={webhookDropdownOptions}
              onChange={(event, selectedOption) => {
                if (selectedOption) {
                  setSelectedWebhookPayload(selectedOption.value);
                  setSelectedDropdownOption(selectedOption);
                }
              }}
              value={selectedDropdownOption}
              clearable={false}
              className="bg-white"
            />
          </div>
          <div
            className="w-1/4 mb-5 flex-col mr-6 font-normal hidden xl:flex"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "ACCOUNT_LINKED"}
              onClick={() => {
                setSelectedWebhookPayload("ACCOUNT_LINKED");
                const obj = findWebhookDropdownOption("ACCOUNT_LINKED");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Linked Account linked
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "LINKED_ACCOUNT_SYNCED"}
              onClick={() => {
                setSelectedWebhookPayload("LINKED_ACCOUNT_SYNCED");

                const obj = findWebhookDropdownOption("LINKED_ACCOUNT_SYNCED");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Linked Account synced
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "LINKED_ACCOUNT_DELETED"}
              onClick={() => {
                setSelectedWebhookPayload("LINKED_ACCOUNT_DELETED");
                const obj = findWebhookDropdownOption("LINKED_ACCOUNT_DELETED");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Linked Account deleted
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "LINKED_ACCOUNT_ISSUES"}
              onClick={() => {
                setSelectedWebhookPayload("LINKED_ACCOUNT_ISSUES");

                const obj = findWebhookDropdownOption("LINKED_ACCOUNT_ISSUES");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Linked Account issues
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "COMMON_MODEL_SYNCED"}
              onClick={() => {
                setSelectedWebhookPayload("COMMON_MODEL_SYNCED");
                const obj = findWebhookDropdownOption("COMMON_MODEL_SYNCED");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Common model synced
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "CHANGED_DATA"}
              onClick={() => {
                setSelectedWebhookPayload("CHANGED_DATA");
                const obj = findWebhookDropdownOption("CHANGED_DATA");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Changed data
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "DELETED_DATA"}
              onClick={() => {
                setSelectedWebhookPayload("DELETED_DATA");
                const obj = findWebhookDropdownOption("DELETED_DATA");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Deleted data
            </NavBarSelectorButton>
            <NavBarSelectorButton
              isSelected={selectedWebhookPayload === "ASYNC_PASSTHROUGH_COMPLETED"}
              onClick={() => {
                setSelectedWebhookPayload("ASYNC_PASSTHROUGH_COMPLETED");
                const obj = findWebhookDropdownOption("ASYNC_PASSTHROUGH_COMPLETED");

                if (obj) {
                  setSelectedDropdownOption(obj);
                }
              }}
            >
              Async Passthrough completed
            </NavBarSelectorButton>
          </div>
          <div className="xl:w-3/4">
            <p>{WEBHOOK_CONTENT_TYPE_TO_PAYLOAD_DESCRIPTION_MAP[selectedWebhookPayload]}</p>
            {selectedWebhookPayload === "COMMON_MODEL_SYNCED" ? (
              <p>
                Event types include{" "}
                <code>
                  {"{"}common_model{"}"}.synced
                </code>
                .
              </p>
            ) : selectedWebhookPayload === "DELETED_DATA" ? (
              <p>
                Event types include{" "}
                <code>
                  {"{"}common_model{"}"}.removed
                </code>
                .
              </p>
            ) : selectedWebhookPayload === "CHANGED_DATA" ? (
              <p>
                Event types include{" "}
                <code>
                  {"{"}common_model{"}"}.added
                </code>{" "}
                and{" "}
                <code>
                  {"{"}common_model{"}"}.changed
                </code>
                .
              </p>
            ) : selectedWebhookPayload === "LINKED_ACCOUNT_ISSUES" ? (
              <p>
                Event types include <code>Issue.new</code>, <code>Issue.reopened</code>, and{" "}
                <code>Issue.resolved</code>.
              </p>
            ) : null}
            <CodeExample
              folder="basics/webhooks/webhook-payload"
              fileBaseName={WEBHOOK_CONTENT_TYPE_TO_PAYLOAD_PATH_MAP[selectedWebhookPayload]}
              codeBlockName="Webhook Payload Example"
              colorScheme="light"
              hideCopyButtonWithHeader
              hasLineNumbers={false}
            />
          </div>
        </div>
      </DocumentationSection>
      <hr className="my-9" />
      <PseudoPaddedSection id="security">
        <DocumentationSection title="Security">
          <>
            <p>
              You&apos;ll want to ensure that your API endpoint is verifying that incoming POST
              requests are from Merge and not a malicious source, and that payloads haven&apos;t
              been altered in transit.
            </p>
            <p>
              The best way to do that is to check that the <code>X-Merge-Webhook-Signature</code>{" "}
              field in the header of the incoming request matches an encoded combination of your
              organization&apos;s webhook signature and the payload attached to the incoming
              request.
            </p>
            <p>
              In your <a href="https://app.merge.dev/configuration/webhooks">Webhooks</a> page under
              configuration, you should see a Security module with your signature key. This key is
              unique to your organization and can be regenerated if it ever becomes known by an
              untrusted third party.
            </p>
            <ImageContainer>
              <WebhookSecurity className="rounded-lg" />
            </ImageContainer>
            <p>
              Using this key, calculate the HMAC-SHA256 of the byte-formatted payload and encode it
              to Base64url to get a digest. Ensure that the digest matches the{" "}
              <code>X-Merge-Webhook-Signature</code> found in the headers of the incoming POST
              request to confirm that the request is valid.
            </p>
            <CodeExample
              folder="basics/webhooks/webhook-security"
              codeBlockName="Securing Webhooks"
            />
          </>
        </DocumentationSection>
      </PseudoPaddedSection>
      <hr className="my-9" />
      <PseudoPaddedSection id="webhook-visibility">
        <DocumentationSection title="Webhook Visibility">
          <>
            <p>
              All Webhooks are visible as Logs, and viewable from Merge&apos;s{" "}
              <a target="_blank" href="https://app.merge.dev/logs/webhooks" rel="noreferrer">
                dashboard
              </a>
              .
            </p>
            <Image fluid src={WebhookLogGif} alt="Merge Link demo in dashboard" />
          </>
        </DocumentationSection>
      </PseudoPaddedSection>
      <GuideContainer className="pt-10">
        <FlexRow>
          <Left>
            <>
              <LinkTitle>Previous</LinkTitle>
              <CrossGuideLink to={"/basics/webhooks/overview"}>
                <ChevronLeft />
                Overview
              </CrossGuideLink>
            </>
          </Left>
          <Right>
            <>
              <LinkTitle>Next</LinkTitle>
              <CrossGuideLink to={"/basics/webhooks/third-party-webhooks"}>
                Third party to Merge
                <ChevronRight />
              </CrossGuideLink>
            </>
          </Right>
        </FlexRow>
      </GuideContainer>
    </StaticPageContainer>
  );
};

export default DocumentationWebhooksMergeToYouPage;
