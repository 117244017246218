import React from "react";
import Markdown from "markdown-to-jsx";
import { OverlayTrigger, Row } from "react-bootstrap";
import styled from "styled-components";
import SmallBranchSVG from "assets/svg/small-branch.svg";
import LargeBranchSVG from "assets/svg/large-branch.svg";
import { DeprecatedBadge, BetaBadge } from "./shared-components/MergeBadges";

const Badge = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #727581;
  margin: 0 6px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  line-height: 20px;
`;

const LeftAlign = styled.div`
  text-align: left;
`;

const RightAlign = styled.div`
  text-align: right;
`;

const MarkdownCode = styled.code`
  && {
    font-size: 13px;
  }
`;

const BranchContainer = styled.div`
  display: flex;
  margin-left: 5px;
`;

const LargeBranchSVGOffset = styled(LargeBranchSVG)`
  position: absolute;
  margin-top: -15px;
`;

const LargeBranchContainer = styled.div`
  padding-left: 13px;
`;

interface ParameterRowProps {
  name: string;
  type: string;
  deprecated?: boolean;
  required: boolean;
  isShowingRequiredFlag?: boolean;
  description?: string;
  hoverOverlay?: JSX.Element;
  children?: JSX.Element | undefined;
  isSmallBranch?: boolean;
  isLargeBranch?: boolean;
  isSingleLine?: boolean;
  isBeta?: boolean;
  rowClassName?: string;
  hrClassName?: string;
}

const ParameterRow = ({
  name,
  type,
  deprecated,
  required,
  isShowingRequiredFlag,
  description,
  hoverOverlay,
  children,
  isSmallBranch = false,
  isLargeBranch = false,
  isSingleLine = false,
  isBeta = false,
  hrClassName,
  rowClassName,
}: ParameterRowProps) => {
  const outerDivClassNames = isSingleLine ? `flex lg:flex-row flex-col w-full` : "";
  const firstRowClassNames = isSingleLine ? "w-1/3 min-w-170" : "";
  const secondRowClassNames = isSingleLine ? "w-2/3" : "";

  const getRow = () => (
    <div className={`${outerDivClassNames} ${rowClassName}`}>
      <Row className={`py-1 mx-0 ${firstRowClassNames}`}>
        <FlexRow>
          <LeftAlign>
            {isSmallBranch || isLargeBranch ? (
              <BranchContainer>
                {isSmallBranch ? <SmallBranchSVG /> : <LargeBranchSVGOffset />}
                {isSmallBranch ? (
                  <div className="ml-3 mt-1">
                    <code>{name}</code>
                    <span className="text-gray-60 font-medium text-sm ml-[5px]">{type}</span>
                    {deprecated && <DeprecatedBadge />}
                    {isBeta && <BetaBadge />}
                  </div>
                ) : (
                  <LargeBranchContainer className="ml-3 mt-1">
                    <code>{name}</code>
                    <span className="text-gray-60 font-medium text-sm ml-[5px]">{type}</span>
                    {deprecated && <DeprecatedBadge />}
                    {isBeta && <BetaBadge />}
                  </LargeBranchContainer>
                )}
              </BranchContainer>
            ) : (
              <div>
                <code className="relative">{name}</code>
                <span className="text-gray-60 font-medium text-sm ml-[5px]">{type}</span>
                {deprecated && <DeprecatedBadge />}
                {isBeta && <BetaBadge />}
              </div>
            )}
          </LeftAlign>
          <RightAlign>
            {isShowingRequiredFlag && <Badge>{required ? "Required" : "Optional"}</Badge>}
          </RightAlign>
        </FlexRow>
      </Row>
      {description && (
        <Row className={`mt-1 mx-0 ${secondRowClassNames}`}>
          <span className="paragraph-m-0">
            <Markdown
              options={{
                overrides: {
                  code: {
                    component: MarkdownCode,
                  },
                },
              }}
            >
              {description}
            </Markdown>
          </span>
        </Row>
      )}
      {children}
    </div>
  );

  return (
    <>
      {isSmallBranch || isLargeBranch ? <></> : <hr className={hrClassName} />}
      {hoverOverlay ? (
        <OverlayTrigger placement="left" delay={{ show: 250, hide: 0 }} overlay={hoverOverlay}>
          {getRow()}
        </OverlayTrigger>
      ) : (
        getRow()
      )}
    </>
  );
};

export default ParameterRow;
